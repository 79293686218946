/* eslint-disable no-restricted-globals */
import axios from 'axios';
import {Whitelabel} from './generic';


//let API_URL = 'https://api.bederr.com/';
let API_URL = 'https://api.bemaven.xyz/';


if (process.env.REACT_APP_URL_REST !== undefined)
   API_URL = process.env.REACT_APP_URL_REST;
const NAME = Whitelabel.name;

const getHeadersAuth = () => {
   let apikey = window.localStorage.getItem(`apiKey`)
   let token = window.localStorage.getItem(`token${NAME}`)

   let headers = {
      'Accept-Language': `es`
   }
   if(apikey!=='undefined' && typeof apikey ===  'string'){
      headers['API-KEY'] = `${apikey}`
   }
   if(token!=='undefined' && typeof token ===  'string'){
      headers['Authorization'] = `Token ${token}`
   }

   return headers;
}


const getHeaders = () => {
   let apikey = window.localStorage.getItem(`apiKey`)
   let headers = {
      'Accept-Language': `es`
   }
   if(apikey!=='undefined' && typeof apikey ===  'string'){
      headers['API-KEY'] = `${apikey}`
   }
   return headers;
}

export default class HttpClient {
   static get(dispatch, type, url) {
      return dispatch({
         type,
         payload: axios.get(`${API_URL}${url}`, {headers: getHeadersAuth()})
            .catch((error) => {
               if (error.response && error.response.status === 401) {
                  window.localStorage.removeItem(`token${NAME}`);
                  location.href = '/login';
               }
            }),
      });
   }

   static getUrl(dispatch, type, url) {
      return dispatch({
         type,
         payload: axios.get(`${url}`, {headers: getHeadersAuth()})
            .catch((error) => {
               if (error.response && error.response.status === 401) {
                  window.localStorage.removeItem(`token${NAME}`);
                  location.href = '/login';
               }
            }),
      });
   }


   static delete(dispatch, type, url) {
      return dispatch({
         type,
         payload: axios.delete(`${API_URL}${url}`, {headers: getHeadersAuth()}),
      });
   }

   static post(data, dispatch, type, url) {
      return dispatch({
         type,
         payload: axios.post(`${API_URL}${url}`, data, {headers: getHeadersAuth()}).then((response) => response)
            .catch((error) => error.response),
      });
   }

   static patch(data, dispatch, type, url) {
      return dispatch({
         type,
         payload: axios.patch(`${API_URL}${url}`, data, {headers: getHeadersAuth()}).catch((error) => error.response),
      });
   }

   static postLogout(data, dispatch, type, url) {
      return dispatch({
         type,
         payload: axios.post(`${API_URL}${url}`, data, {headers: getHeaders()}).then((response) => response)
            .catch((error) => error.response),
      });
   }

   static getLogout(dispatch, type, url) {
      return dispatch({
         type,
         payload: axios.get(`${API_URL}${url}`, {headers: getHeaders()}).then((response) => response)
            .catch((error) => error.response),
      });
   }
}
