import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import bederrEn from './en/bederr.json';
import createUserEn from './en/createUser.json';
import historyEn from './en/history.json';
import loginEn from './en/login.json';
import mainMenuEn from './en/mainMenu.json';
import ordersEn from './en/orders.json';
import placesEn from './en/places.json';
import promotionsEn from './en/promotions.json';
import transferredEn from './en/transferred.json';

import bederrEs from './es/bederr.json';
import createUserEs from './es/createUser.json';
import historyEs from './es/history.json';
import loginEs from './es/login.json';
import mainMenuEs from './es/mainMenu.json';
import ordersEs from './es/orders.json';
import placesEs from './es/places.json';
import promotionsEs from './es/promotions.json';
import transferredEs from './es/transferred.json';

const resources = {
    en: {
        bederr: bederrEn,
        createUser: createUserEn,
        history: historyEn,
        login: loginEn,
        mainMenu: mainMenuEn,
        orders: ordersEn,
        places: placesEn,
        promotions: promotionsEn,
        transferred: transferredEn,
    },
    es: {
        bederr: bederrEs,
        createUser: createUserEs,
        history: historyEs,
        login: loginEs,
        mainMenu: mainMenuEs,
        orders: ordersEs,
        places: placesEs,
        promotions: promotionsEs,
        transferred: transferredEs,
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'es',
        fallbackLng: 'es',
        ns: ['bederr',
            'createUser',
            'history',
            'login',
            'mainMenu',
            'orders',
            'places',
            'promotions',
            'transferred'],
        defaultNS: 'bederr',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
