import React, {Component} from 'react';
import './style.scss';
import i18n from "../../components/i18n";

export default class LanguageSelector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: "es",
        }
    }

    componentDidUpdate() {
        this.updateComponent();
        console.log(this.state.lang, localStorage.getItem("lang"))
        if(this.state.lang!==localStorage.getItem("lang")){
            this.setState({lang: localStorage.getItem("lang")})
            i18n.changeLanguage(localStorage.getItem("lang")); // Cambia el idioma global
        }
    }


    changeLanguage = (lang) => {
        i18n.changeLanguage(lang); // Cambia el idioma global
        localStorage.setItem("lang", lang)
    };

  render() {
    return (
        <div className="language-selector language-container">

            <select
                onChange={(e) => this.changeLanguage(e.target.value)}
                className="language-select"
                defaultValue={localStorage.getItem("lang")}
            >
                <option value="es">Español</option>
                <option value="en">English</option>

            </select>
        </div>
    )
  }
}