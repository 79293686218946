/* eslint-disable react/sort-comp */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import logo from "../../images/logo.png";
import { cleanBusiness, loginUser, searchBusiness } from "./actions";
import { Whitelabel } from "../../utils/generic";
import Validator from "../../utils/validator";
import "./style.scss";
import { withTranslation } from "react-i18next";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      business: "",
      apiKey: "",
      password: "",
      error: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(cleanBusiness());
    window.localStorage.setItem(`apiKey`, "");
    this.validator = new Validator([
      {
        id: "email",
        ref: this.emailInput,
        rules: ["required", "email"],
      },
      {
        id: "password",
        ref: this.passwordInput,
        rules: ["required"],
      },
    ]);
  }

  handlePassword = (event) =>
    this.setFormValues({ password: event.target.value });
  handleEmail = (event) => this.setFormValues({ email: event.target.value });

  handleBusiness = (event) => {
    this.props.dispatch(cleanBusiness());
    this.setFormValues({ business: event.target.value });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    const { business, user } = nextProps;
    if (business != null) {
      if (this.state.apiKey === "") {
        this.setState({ apiKey: business.api_key });
        window.localStorage.setItem(`apiKey`, business.api_key);
        window.localStorage.setItem(`subdomain`, business.domain.subdomain);
        window.localStorage.setItem(`companyUid`, business.uid);
        this.loginUser();
      }
    } else {
      if (this.state.apiKey !== "") {
        this.setState({ apiKey: "" });
        window.localStorage.setItem(`apiKey`, "");
        window.localStorage.setItem(`subdomain`, "");
        window.localStorage.setItem(`companyUid`, "");
      }
    }
    if (user != null) {
      this.openSession(user);
    }
  }

  openSession = (user) => {
    const { token } = user;
    const NAME = Whitelabel.name;
    window.localStorage.setItem(`token${NAME}`, token);
    setTimeout(() => {
      document.location.href = "/";
      return true;
    }, 1000);
  };

  loginUser = () => {
    this.props.dispatch(
      loginUser({
        email: this.state.email,
        password: this.state.password,
      })
    );
  };

  onSubmitForm = async (event) => {
    event.preventDefault();
    let { isFetchingBusiness } = this.props;
    if (
      !isFetchingBusiness &&
      this.validator.validate({
        business: this.state.business,
        email: this.state.email,
        password: this.state.password,
      })
    ) {
      this.setState({ apiKey: "" });
      this.props.dispatch(searchBusiness(this.state.business));
    }
  };

  setFormValues = (propertiesToUpdate) => {
    this.setState({ error: "" });
    this.setState(propertiesToUpdate);
    this.validator.validate(propertiesToUpdate);
  };

  render() {
    const { isFetchingBusiness, errorBusiness, isFetching, error } = this.props;
    const { t } = this.props;

    return (
        <div className="container-login">
          <div className="box-login">
            <div className="form-login">
              <div className="hello">
                <img src={logo} className="logo" alt="logo" />
                <h2>{t('login:title')}</h2>
              </div>
              <form className="form" onSubmit={this.onSubmitForm}>
                <div>
                  <div
                      className="input-group"
                      ref={(input) => {
                        if (input) {
                          this.businessInput = input.querySelector("input");
                        }
                      }}
                  >
                    <input
                        className="form-control bussines"
                        id="business"
                        type="string"
                        placeholder={t('login:placeholder.business')}
                        value={this.state.business}
                        onChange={this.handleBusiness}
                        disabled={isFetchingBusiness || isFetching}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">.bederr.com</span>
                    </div>
                  </div>
                  {errorBusiness && (
                      <span className="field-error-message">
                  {errorBusiness.detail === t('login:errors.businessNotFound')
                      ? t('login:errors.businessNotFound')
                      : t('login:errors.businessInvalid')}
                </span>
                  )}
                </div>
                <div>
                  <div
                      className="input-group"
                      ref={(input) => {
                        if (input) {
                          this.emailInput = input.querySelector("input");
                        }
                      }}
                  >
                    <input
                        className="form-control"
                        id="username"
                        type="email"
                        placeholder={t('login:placeholder.email')}
                        value={this.state.username}
                        onChange={this.handleEmail}
                        disabled={isFetchingBusiness || isFetching}
                    />
                  </div>
                  {error && error.email && (
                      <span className="field-error-message">
                  {t('login:errors.emailInvalid')}
                </span>
                  )}
                </div>

                <div>
                  <div
                      className="input-group"
                      ref={(input) => {
                        if (input) {
                          this.passwordInput = input.querySelector("input");
                        }
                      }}
                  >
                    <input
                        className="form-control"
                        id="password"
                        type="password"
                        placeholder={t('login:placeholder.password')}
                        value={this.state.password}
                        onChange={this.handlePassword}
                        disabled={isFetchingBusiness || isFetching}
                    />
                  </div>
                  {error && error.password && (
                      <span className="field-error-message">
                  {t('login:errors.passwordInvalid')}
                </span>
                  )}
                </div>
                {error && error.non_field_errors && (
                    <div className="error-login-alert" role="alert">
                      <strong>{t('login:errors.nonFieldError')}</strong>
                    </div>
                )}

                <div className="btn-login">
                  <button type="submit">
                    {isFetchingBusiness || isFetching
                        ? t('login:button.loading')
                        : t('login:button.login')}
                  </button>
                </div>
              </form>
            </div>
            <div className="publicity"></div>
          </div>
          <div className="footer-login">
            <h2>{t('login:footer')}</h2>
          </div>
        </div>
    );
  }

}

Login.defaultProps = {
  business: null,
  errorBusiness: null,
  isFetchingBusiness: false,
  user: null,
  error: null,
  isFetching: false,
};

Login.propTypes = {
  dispatch: PropTypes.func,
  business: PropTypes.object,
  errorBusiness: PropTypes.object,
  isFetchingBusiness: PropTypes.bool,
  user: PropTypes.object,
  error: PropTypes.object,
  isFetching: PropTypes.bool,
};

export default connect(({ user }) => ({
  business: user.business,
  errorBusiness: user.errorBusiness,
  isFetchingBusiness: user.isFetchingBusiness,
  user: user.user,
  error: user.error,
  isFetching: user.isFetching,
}))(withTranslation()(Login));
